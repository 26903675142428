






































































import Vue from 'vue';
import { menuRoutes } from '@/router/index';
import UserListDownloader from '@/context/user/components/UserListDownloader.vue';

export default Vue.extend({
  name: 'App',
  components: { UserListDownloader },
  created() {
    document.title = 'Administracja portfelami';
  },
  data: () => ({
    drawer: false,
    items: menuRoutes
    //
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    }
  },
  methods: {
    activeClass(item: any) {
      return this.$route.path.includes(item.path);
    },
    redirectToApp() {
      window.location.href = process.env.VUE_APP_PRODUCTION_URL;
    }
  }
});
