import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";

Vue.use(VueRouter);

export const routes: Array<RouteConfig> = [
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    name: "Entrypoint",
    path: "/entrypoint/:token",
    meta: { icon: "mdi-home" },
    component: () =>
      import(
        /* webpackChunkName: "application-entrypoint" */ "../context/application-entrypoint/pages/index.vue"
      )
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    name: "Dodaj rekomendacje",
    path: "/recommendations/create",
    meta: { icon: "mdi-home" },
    component: () =>
      import(
        /* webpackChunkName: "recommendations-create" */ "../context/recommendations/pages/recommendations/RecommendationsCreatePage.vue"
      )
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    name: "Lista rekomendacji",
    path: "/recommendations/list",
    meta: { icon: "mdi-home" },
    component: () =>
      import(
        /* webpackChunkName: "recommendations-list" */ "../context/recommendations/pages/recommendations/RecommendationsListPage.vue"
      )
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    name: "Lista odbiorców rekomendacji",
    path: "/recipients/recommendation",
    meta: { icon: "mdi-home" },
    component: () =>
      import(
        /* webpackChunkName: "recommendations-recipients-list" */ "../context/recommendations/pages/recipients/RecommendationRecipientsListPage.vue"
      )
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    name: "Lista odbiorców newslettera",
    path: "/recipients/newsletter",
    meta: { icon: "mdi-home" },
    component: () =>
      import(
        /* webpackChunkName: "newsletter-recipients-list" */ "../context/recommendations/pages/recipients/NewsletterRecipientsListPage.vue"
      )
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    name: "Lista odbiorców cen energii",
    path: "/recipients/energy",
    meta: { icon: "mdi-home" },
    component: () =>
      import(
        /* webpackChunkName: "energy-recipients-list" */ "../context/recommendations/pages/recipients/EnergyRecipientsListPage.vue"
      )
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    name: "Lista odbiorców cen gazu",
    path: "/recipients/gas",
    meta: { icon: "mdi-home" },
    component: () =>
      import(
        /* webpackChunkName: "gas-recipients-list" */ "../context/recommendations/pages/recipients/GasRecipientsListPage.vue"
      )
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    name: "Lista odbiorców potwierdzeń rekomendacji",
    path: "/recipients/recommendation_confirmation",
    meta: { icon: "mdi-home" },
    component: () =>
      import(
        /* webpackChunkName: "gas-recipients-list" */ "../context/recommendations/pages/recipients/RecommendationConfirmationRecipientsListPage.vue"
      )
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    name: "Potwierdzanie ceny",
    path: "/prices/approval",
    meta: { icon: "mdi-home" },
    component: () =>
      import(
        /* webpackChunkName: "gas-recipients-list" */ "../context/price-approval/pages/PriceApprovalPage.vue"
      )
  },

  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    name: "Dodaj użytkownika",
    path: "/users/create",
    meta: { icon: "mdi-home" },
    component: () => import("../context/user/pages/CreateUserPage.vue")
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    name: "Tworzenie klienta",
    path: "/clients/create",
    meta: { icon: "mdi-home" },
    component: () =>
      import(
        /* webpackChunkName: "gas-recipients-list" */ "../context/clients/pages/ClientCreatePage.vue"
      )
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    name: "tasdasasd",
    path: "/reagrements/edit/:id",
    meta: { icon: "mdi-home" },
    component: () =>
      import(
        /* webpackChunkName: "gas-recipients-list" */ "../context/agreements/containers/EditAgreementContainer.vue"
      )
  },

  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    name: "Umowy",
    path: "/agreements",
    meta: {
      icon: "mdi-home",
      breadcrumb: "Umowa"
    },
    component: () =>
      import(
        /* webpackChunkName: "agreement-page" */ "../context/agreements/pages/AgreementPage.vue"
      ),
    children: [
      {
        path: "edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "agreement-edit-page" */ "../context/agreements/containers/EditAgreementContainer.vue"
          ),
        meta: { breadcrumb: "Edycja" }
      },
      {
        path: "create",
        component: () =>
          import(
            /* webpackChunkName: "agreement-create-page" */ "../context/agreements/containers/CreateAgreementContainer.vue"
          ),
        meta: { breadcrumb: "Tworzenie" }
      },
      {
        path: "list",
        component: () =>
          import(
            /* webpackChunkName: "agreement-create-page" */ "../context/agreements/containers/AgreementListContainer.vue"
          ),
        meta: { breadcrumb: "Lista" }
      }
    ]
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    name: "Portfele",
    path: "/wallets",
    meta: {
      icon: "mdi-home",
      breadcrumb: "Portfel"
    },
    component: () =>
      import(/* webpackChunkName: "agreement-page" */ "../context/wallets/pages/WalletPage.vue"),
    children: [
      {
        path: "lot",
        component: () =>
          import(
            /* webpackChunkName: "agreement-create-page" */ "../context/wallets/containers/FormContainer.vue"
          ),
        meta: { breadcrumb: "Dodawanie transzy" }
      },
      {
        path: "statuses",
        component: () =>
          import(
            /* webpackChunkName: "agreement-create-page" */ "../context/wallets/containers/LotCreationStatuses.vue"
            ),
        meta: { breadcrumb: "Statusy dodanych transz" }
      },
      {
        path: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "agreement-create-page" */ "../context/wallets/containers/WalletPurchaseDashboardContainer.vue"
          ),
        meta: { breadcrumb: "Dashboard zakupów" }
      },
      {
        path: "recommendation",
        component: () =>
          import(
            /* webpackChunkName: "agreement-create-page" */ "../context/wallets/containers/WalletRecommendationDashboardContainer.vue"
          ),
        meta: { breadcrumb: "Dashboard rekomendacji" }
      },
      {
        path: "xls",
        component: () =>
          import(
            /* webpackChunkName: "agreement-create-page" */ "../context/wallets/containers/DownloadContainer.vue"
          ),
        meta: { breadcrumb: "Dashboard rekomendacji" }
      }
    ]
  },
  {
    // UserProfile will be rendered inside User's <router-view>
    // when /user/:id/profile is matched
    name: "Grupy zakupowe",
    path: "/purchase_groups",
    meta: {
      icon: "mdi-home",
      breadcrumb: "Grupy zakupowe"
    },
    component: () =>
      import(
        /* webpackChunkName: "purchase-group-page" */ "../context/purchase_groups/pages/PurchaseGroupPage.vue"
      ),
    children: [
      {
        path: "edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "purchase-group-edit-page" */ "../context/purchase_groups/containers/EditPurchaseGroupContainer.vue"
          ),
        meta: { breadcrumb: "Edycja" }
      },
      {
        path: "show/:id",
        component: () =>
          import(
            /* webpackChunkName: "purchase-group-edit-page" */ "../context/purchase_groups/containers/PurchaseGroupDetailsContainer.vue"
          ),
        meta: { breadcrumb: "Szczegóły" }
      },
      {
        path: "create",
        component: () =>
          import(
            /* webpackChunkName: "agreement-create-page" */ "../context/purchase_groups/containers/CreatePurchaseGroupContainer.vue"
          ),
        meta: { breadcrumb: "Tworzenie" }
      },
      {
        path: "list",
        component: () =>
          import(
            /* webpackChunkName: "agreement-create-page" */ "../context/purchase_groups/containers/PurchaseGroupListContainer.vue"
          ),
        meta: { breadcrumb: "Lista" }
      }
    ]
  }
];

export const menuRoutes = [
  {
    name: "Rekomendacje",
    path: "/recommendations",
    meta: { icon: "mdi-home" },
    children: [
      {
        name: "Tworzenie",
        path: "/recommendations/create",
        meta: { icon: "mdi-home" }
      },
      {
        name: "Lista",
        path: "/recommendations/list",
        meta: { icon: "mdi-home" }
      }
    ]
  },
  {
    name: "Odbiorcy",
    path: "/recipients",
    meta: { icon: "mdi-home" },
    children: [
      {
        name: "Rekomendacji",
        path: "/recipients/recommendation",
        meta: { icon: "mdi-home" }
      },
      {
        name: "Potwierdzeń rekomendacji",
        path: "/recipients/recommendation_confirmation",
        meta: { icon: "mdi-home" }
      },
      {
        name: "Newslettera",
        path: "/recipients/newsletter",
        meta: { icon: "mdi-home" }
      },
      {
        name: "Cen energii",
        path: "/recipients/energy",
        meta: { icon: "mdi-home" }
      },
      {
        name: "Cen gazu",
        path: "/recipients/gas",
        meta: { icon: "mdi-home" }
      }
    ]
  },
  {
    name: "Użytkownicy",
    path: "/users",
    meta: { icon: "mdi-home" },
    children: [
      {
        name: "Tworzenie",
        path: "/users/create",
        meta: { icon: "mdi-home" }
      }
    ]
  },
  {
    name: "Ceny",
    path: "/prices",
    meta: { icon: "mdi-home" },
    children: [
      {
        name: "Potwierdzanie",
        path: "/prices/approval",
        meta: { icon: "mdi-home" }
      }
    ]
  },
  {
    name: "Klient",
    path: "/clients",
    meta: { icon: "mdi-home" },
    children: [
      {
        name: "Tworzenie",
        path: "/clients/create",
        meta: { icon: "mdi-home" }
      }
    ]
  },
  {
    name: "Umowy",
    path: "/agreements",
    meta: { icon: "mdi-home" },
    children: [
      {
        name: "Tworzenie",
        path: "/agreements/create",
        meta: { icon: "mdi-home" }
      },
      {
        name: "Lista",
        path: "/agreements/list",
        meta: { icon: "mdi-home" }
      }
    ]
  },
  {
    name: "Portfele",
    path: "/wallets",
    meta: { icon: "mdi-home" },
    children: [
      {
        name: "Dodaj transze",
        path: "/wallets/lot",
        meta: { icon: "mdi-home" }
      },
      {
        name: "Lista statusów",
        path: "/wallets/statuses",
        meta: { icon: "mdi-home" }
      },
      {
        name: "Lista zakupów",
        path: "/wallets/dashboard",
        meta: { icon: "mdi-home" }
      },
      {
        name: "Przypomnienie o końcowej dacie zakupów",
        path: "/wallets/recommendation",
        meta: { icon: "mdi-home" }
      },
      {
        name: "Podgląd cen hurtowych",
        path: "/wallets/xls",
        meta: { icon: "mdi-home" }
      }
    ]
  },
  {
    name: "Grupy zakupowe",
    path: "/purchase_groups",
    meta: { icon: "mdi-home" },
    children: [
      {
        name: "Tworzenie grupy zakupowej",
        path: "/purchase_groups/create",
        meta: { icon: "mdi-home" }
      },
      {
        name: "Lista grup zakupowych",
        path: "/purchase_groups/list",
        meta: { icon: "mdi-home" }
      }
    ]
  }
];

const router = new VueRouter({
  routes
});
router.beforeEach((to: Route, from: Route, next: NavigationGuardNext<Vue>) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (localStorage.getItem("token") === null) {
      window.location.href = process.env.VUE_APP_PRODUCTION_URL;
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
