









import Vue from 'vue';
import Component from 'vue-class-component';
import {AxiosResponse} from "axios";

@Component({
  components: {}
})
export default class UserListDownloader extends Vue {

  handle() {
    const header = {
      'Content-Type': 'multipart/form-data'
    }
    const response = this.$http.get('api/users/xls', {
      headers: header,
      responseType: 'arraybuffer'
    })
      .then((response: AxiosResponse) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'lista użytkowników.xlsx')
        document.body.appendChild(link)
        link.click()
      })
  }

}
