import moment from "moment";

export function booleanToString(value: boolean): string {
  return value ? "Tak" : "Nie";
}

export function formatDateTime(value: Date): string {
  return value ? moment(String(value)).format("YYYY-MM-DD HH:mm") : "-";
}

export function formatTimeStamp(value: number): string {
  return value ? moment.unix(value).format("YYYY-MM-DD HH:mm") : "-";
}

export function toFixedDecimalPlaces(value: number, places: number) {
  if (value == null) {
    value = Number(0);
  }
  return value.toFixed(places);
}
