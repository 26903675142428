import axios from "axios";
import _Vue from "vue";
import jwtDecode, { JwtPayload } from "jwt-decode";

export class AxiosPluginOptions {
  // add stuff
}

export const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_AXIOS_BASE_URL
});

axiosInstance.interceptors.request.use(async config => {
  const token = localStorage.getItem("wallet-frontend-token");
  if (token && token != "") {
    config.headers = {
      Authorization: `Bearer ${token}`
    };
  } else {
    window.location.href = process.env.VUE_APP_PRODUCTION_URL;
  }
  return config;
});
const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response;
    localStorage.removeItem("wallet-frontend-token");
    if (status === UNAUTHORIZED) {
      window.location.href = process.env.VUE_APP_PRODUCTION_URL;
    }
    return Promise.reject(error);
  }
);
export function AxiosPlugin<AxiosPlugOptions>(
  Vue: typeof _Vue,
  options?: AxiosPluginOptions
): void {
  // do stuff with options
  // eslint-disable-next-line no-param-reassign
  Vue.prototype.$http = axiosInstance;
}

axiosInstance.interceptors.request.use(config => config);
