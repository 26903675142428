import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pl from 'vuetify/src/locale/pl'
import en from 'vuetify/src/locale/en'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#f2961c",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#b71c1c",
        background: "#000000",
        color: "#FFFFFF"
      }
    },
  },
  lang: {
    locales: { pl, en },
    current: 'pl',
  },
});
