import Vue from "vue";
import { AxiosPlugin, axiosInstance } from "@/plugins/axios";
import VueI18n from "vue-i18n";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Toasted from "vue-toasted";
import * as Sentry from "@sentry/vue";
import {
  booleanToString,
  formatDateTime,
  formatTimeStamp,
  toFixedDecimalPlaces
} from "@/plugins/custom-filters";
import i18n from "@/plugins/i18n";
// import plugin
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
// don't forget to import CSS styles
import "tiptap-vuetify/dist/main.css";
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi"
});
Vue.config.productionTip = false;
Vue.use(AxiosPlugin, axiosInstance);
Vue.use(Toasted, {
  className: "v-application",
  containerClass: "v-application",
  iconPack: "mdi"
});
Vue.filter("booleanToString", booleanToString);
Vue.filter("formatDateTime", formatDateTime);
Vue.filter("formatTimeStamp", formatTimeStamp);
Vue.filter("toFixedDecimalPlaces", toFixedDecimalPlaces);
if(process.env.VUE_APP_SETNRY_IMPLEMENTATION === "true"){
  Sentry.init({
    Vue,
    dsn: "https://bd6f1615093848afa6d4c0278060d32c@o4504513305378816.ingest.sentry.io/4504972351307776",
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT_NAME,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", "my-site-url.com", /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const v = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
